



















import { defineComponent, SetupContext } from '@vue/composition-api'

import { asyncCardCheckProps, useAsyncCardCheck } from './AsyncCardCheck.hooks'
import { AsyncCardCheckProps } from './AsyncCardCheck.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const AsyncCardCheck = defineComponent({
  name: 'AsyncCardCheck',
  props: asyncCardCheckProps,

  setup (props: AsyncCardCheckProps, ctx: SetupContext) {
    return useAsyncCardCheck(props, ctx)
  }
})

export default AsyncCardCheck
